<!--
 * @Description: 
 * @Author: fely
 * @Date: 2021-02-02 16:10:29
 * @LastEditTime: 2021-02-27 17:08:18
 * @LastEditors: fely
-->
<template>
  <div class="wrapper">
    <div class="header">
      <top-header></top-header>
      <div class="text-con">
        <h1>亿慧工资条</h1>
        <span @click="goGZT" class="gztURL">gzt.gjyxms.com</span>
      </div>
    </div>
    <div class="content-contain">
      <div class="intro-wrapper">
        <div class="text-box">
          <p class="big-title">Excle 导入</p>
          <p class="small-title">Excle Import</p>
          <p class="detail">企业用户在小秘书工资条网站上传日常使用的excel表，系统自动拆分成工资条</p>
        </div>
        <div class="image-box">
          <img style="height: 408px;" src="~@/assets/images/slip1.png">
        </div>
      </div>
      <div class="intro-wrapper" style="margin-top: 76px;">
        <div class="image-box">
          <img style="height: 408px;" src="~@/assets/images/slip2.png">
        </div>
        <div class="text-box">
          <p class="big-title">一键群发</p>
          <p class="small-title">One click Group</p>
          <p class="detail">
            将工资条一键群发给员工，员工可在亿慧小秘书app端及时查看。</p>
        </div>
    
      </div>
      <div class="intro-wrapper" style="margin-top: 76px;">
        <div class="text-box">
          <p class="big-title">私密查看</p>
          <p class="small-title">Private check</p>
          <p class="detail">员工在亿慧小秘书app端必须设置并输入安全码才能查看工资条，防止敏感数据泄露。</p>
        </div>
        <div class="image-box">
          <img src="~@/assets/images/slip3.png">
        </div>
      </div>
      <div style="margin-top: 40px;background-color: #F1F4FF;width: 100%;height: 464px;display: flex;justify-content: center;align-items: center;">
        <div class="intro-wrapper">
          <div class="image-box">
            <img style="height: 330px;" src="~@/assets/images/slip4.png">
          </div>
          <div class="text-box">
            <p class="big-title">数据安全</p>
            <p class="small-title">Data security</p>
            <p class="detail">采用多种方式保证工资数据的安全存储、加密数据传输，保证用户安全使用。</p>
          </div>
        
        </div>
      </div>
      
      <div class="footer con-foot">
        <p>©天津市亿慧网络科技有限公司 版权所有 <a class="beian"  target="_black" href="http://beian.miit.gov.cn">津ICP备20006513号</a></p>
        <div style="margin-left: 20px;">
          <a target="_blank" href="http://www.beian.gov.cn/"
            style="display:inline-block;text-decoration:none;height:20px;line-height:20px;">
            <img src="~@/assets/images/police.png" style="float:left;" />
            <p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">津公网安备 12011102001059号</p>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopHeader from './topHeader'
export default {
  name: '',
  data() {
    return {

    }
  },
  components: {
    TopHeader
  },
  methods: {
    goGZT(){
      window.open("http://gzt.gjyxms.com")
    }
  },
}
</script>

<style scoped lang="less">
@import '../styles/public.css';
@import '../styles/common.css';
.wrapper{
  height: 100%;
}
.gztURL{
  cursor: pointer;
}
</style>
